import React, { useEffect, createContext } from 'react';
import useAuth from 'dashboard/src/hooks/useAuth';
import { AxiosInstance } from 'axios';
import axios from 'axios';
import { parseJwt } from 'dashboard/src/utils/parseToken';

const axiosInstance = axios.create();

const AxiosContext = createContext<AxiosInstance>(null);

export function AxiosProvider({ children }) {
  const { client, accessToken } = useAuth();

  useEffect(() => {
    const interceptorId = axiosInstance.interceptors.request.use(async (config) => {
      let token = accessToken || localStorage.getItem('token');
      if (accessToken) {
        const tokenData = parseJwt(accessToken);
        const isExpired = tokenData.exp < Date.now() / 1000;

        if (accessToken && isExpired) {
          const { organization } = (window as any).store?.getState()?.auth || {};
          try {
            token = await (window as any).auth0.getTokenSilently();
          } catch (error) {
            await (window as any).auth0.loginWithRedirect({
              redirect_uri: `${window.location.origin}/callback?organization=${organization}`,
              organization,
            });
          }
        }
        const commons ={
          ...config.headers.common,
          context: "AxiosContext"
        }

        // if the request is not to the azure blob storage, add the token to the headers. otherise azure returns an error
        if (!config.url.includes('blob.core.windows.net')) {
          commons.authorization = `Bearer ${token}`;
        }
        return {
          ...config,
          headers: {
            ...config.headers,
            common: commons
          }
        }
      }
      return config;
    });
    return () => axiosInstance.interceptors.request.eject(interceptorId);
  }, [client, accessToken])


  return (
    <AxiosContext.Provider value={axiosInstance as AxiosInstance}>
      { children}
    </AxiosContext.Provider >
  )
}


export default AxiosContext;
