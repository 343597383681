import React from 'react'

import { setContext } from '@apollo/client/link/context';

import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';

const httpLink = createHttpLink({
  uri: '/api/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      context:"ApolloContext"
    }
  }
});





export const ApolloAuthProvider = ({ children }) => {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}
